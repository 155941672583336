<template>
	<v-layout column fill-height pa-3 scroll-y>
		<v-flex v-t="'customization.description'" headline mb-3 shrink />

		<v-flex shrink>
			<WelcomeMessageForm />
		</v-flex>

		<v-divider class="my-3"></v-divider>

		<v-flex shrink>
			<v-flex v-t="'customization.white_label_data'" mb-2 shrink title />
			<v-layout>
				<WhiteLabelForm :value="themes" @saveDark="updateWhiteLabel($event, 'dark')" @saveLight="updateWhiteLabel($event, 'light')" />
			</v-layout>
		</v-flex>
		<ColorSuggestionDialog :colors="suggestedColors" :theme="suggestedTheme" :value="doSuggestColors" @input="closeDialog()" />
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomizationModuleGuard from '@/mixins/ModulesGuards/Customization/CustomizationModuleGuard'

export default {
	name: 'CustomizationModule',
	components: {
		ColorSuggestionDialog: () => ({
			component: import('@/components/Customization/ColorSuggestionDialog')
		}),
		WelcomeMessageForm: () => ({
			component: import('@/components/Customization/WelcomeMessageForm')
		}),
		WhiteLabelForm: () => ({
			component: import('@/components/WhiteLabel/WhiteLabelForm')
		})
	},
	mixins: [CustomizationModuleGuard],
	data: function () {
		return {
			doSuggestColors: false,
			suggestedColors: [],
			suggestedTheme: null,
			tab: 0
		}
	},
	computed: {
		...mapState({
			themes: state => state.whitelabel.list
		})
	},
	methods: {
		closeDialog: function () {
			new Promise(resolve => {
				this.doSuggestColors = false
				this.suggestedColors = []
				this.suggestedTheme = null

				resolve()
			})
		},
		findWhiteLabel: function (theme) {
			return this.themes.find(whiteLabel => whiteLabel.theme === theme)
		},
		suggestColors: function (theme) {
			return this.service.listColorsOfLogo(this.accountingFirmId, theme).then(colors => {
				this.suggestedColors = colors
				this.suggestedTheme = theme
				this.doSuggestColors = true
			})
		},
		updateWhiteLabel: function (whiteLabel, theme) {
			const data = {}

			return new Promise(resolve => {
				const value = this.findWhiteLabel(theme)
				if (whiteLabel.logo !== value.logo) {
					data.logo = whiteLabel.logo
				}
				if (whiteLabel.primary_color !== value.primary_color) {
					data.primary_color = whiteLabel.primary_color
				}
				if (whiteLabel.secondary_color !== value.secondary_color) {
					data.secondary_color = whiteLabel.secondary_color
				}
				const promise = this.service.updateWhiteLabel(this.accountingFirmId, theme, data)

				resolve(promise)
			}).then(() => {
				if (data.hasOwnProperty('logo') && !data.hasOwnProperty('primary_color') && !data.hasOwnProperty('secondary_color')) {
					return this.suggestColors(theme)
				}
			})
		}
	}
}
</script>
