// ============================================================================
// CustomizationService
// --------------------
// Customization module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/CustomizationApi'

import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

// -------
// Exports
// -------
export default {
	modifyWelcomeMessage: function (accountingFirmId, welcomeMessage) {
		return API.modifyWelcomeMessage(accountingFirmId, {
			welcome_message: welcomeMessage
		})
			.then(response => response.data.data)
			.then(accountingFirm => {
				store.dispatch('accountingFirm/updateSelectedAccountingFirm', accountingFirm)
			})
	},
	updateWhiteLabel: function (accountingFirmId, theme, data) {
		let apiData = new FormData()
		if (data.hasOwnProperty('logo')) {
			apiData.append('logo', data.logo)
		}
		if (data.hasOwnProperty('primary_color')) {
			apiData.append('primary_color', data.primary_color)
		}
		if (data.hasOwnProperty('secondary_color')) {
			apiData.append('secondary_color', data.secondary_color)
		}
		return API.updateWhiteLabel(accountingFirmId, theme, apiData)
			.then(res => res.data.data)
			.then(whiteLabel => {
				AppEventBus.emit(AppEvents.WHITE_LABEL_UPDATED, whiteLabel)
			})
	},
	listColorsOfLogo: function (accountingFirmId, theme) {
		return API.listColorsOfLogo(accountingFirmId, theme).then(response => response.data.data)
	}
}
