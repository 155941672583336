import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const modifyWelcomeMessage = (accountingFirmId, data) => {
	const url = `accounting-firm/${accountingFirmId}/welcome-message`

	return axios.patch(url, data)
}

const updateWhiteLabel = (accountingFirmId, theme, data) => {
	const url = `accounting-firms/${accountingFirmId}/white-labels/${theme}`
	data.append('_method', 'PATCH')
	return axios.post(url, data)
}

const listColorsOfLogo = (accountingFirmId, theme) => {
	const url = `accounting-firms/${accountingFirmId}/white-labels/${theme}/logo/colors`

	return axios.get(url)
}

export default {
	modifyWelcomeMessage,
	updateWhiteLabel,
	listColorsOfLogo
}
